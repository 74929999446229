import type { GetServerSideProps, NextPage } from "next";
import { signIn } from "next-auth/react";
import Link from "next/link";
import { FaLine } from "react-icons/fa";
import { DOCS_URL } from "src/config";
import { getServerSession } from "src/lib/next-auth";

const Web: NextPage = () => {
  return (
    <section className="bg-[#F4F7FF] py-20 min-h-screen">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="relative mx-auto max-w-[440px] overflow-hidden rounded-lg bg-white py-16 px-7 sm:px-12">
              <div className="items-center justify-center flex">
                <Link href="/">
                  <img
                    src="/images/logo.svg"
                    alt="logo"
                    className="h-8 w-auto"
                  />
                </Link>
              </div>

              <div className="mt-10">
                <h2 className="text-2xl font-bold text-dark text-center">
                  ログイン
                </h2>
              </div>

              <div className="mt-4">
                <p className="text-sm text-body-color">
                  登録することで、
                  <a
                    href={DOCS_URL.TERMS}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="underline"
                  >
                    利用規約
                  </a>
                  および
                  <a
                    href={DOCS_URL.PRIVACY}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="underline"
                  >
                    プライバシーポリシー
                  </a>
                  に同意したものとみなされます。
                </p>
              </div>

              <div className="flex mt-4 items-center justify-center space-x-5">
                <button
                  type="button"
                  onClick={() => signIn("line")}
                  className="rounded bg-[#06C755] py-3 px-7 text-base font-medium text-white hover:bg-opacity-90 flex items-center space-x-2 w-full justify-center"
                >
                  <FaLine className="w-4 h-4" />
                  <span>LINEで登録・ログイン</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getServerSession(context);
  if (session) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};

export default Web;
