export const API_URL = process.env.NEXT_PUBLIC_API_URL as string;
// For local development
export const API_URL_HTTPS = "https://localhost:3002";

export const DOCS_URL = {
  PRIVACY:
    "https://opttag.notion.site/askmate-fcbb20984b574218ba3f464984b37257",
  TERMS: "https://opttag.notion.site/askmate-71cde9dafcc8498a8b0d005579ddcb66",
  TOKUSHO:
    "https://opttag.notion.site/askmate-1742601782fc8020a4e5dfdb0da176f3",
};

export const LINE_URL = process.env.NEXT_PUBLIC_LINE_URL as string;
