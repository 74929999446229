import { atom, useAtom } from "jotai";

export type Notification = {
  id: string;
  type: "info" | "warning" | "success" | "error";
  title: string;
  duration?: number;
  message?: string;
};

const notificationsAtom = atom<Notification[]>([]);
const addNotificationAtom = atom(
  null,
  (_, set, notification: Omit<Notification, "id">) => {
    const id = window.crypto.randomUUID();
    set(notificationsAtom, (state) => [...state, { id, ...notification }]);
    // Automatically disappear
    setTimeout(() => {
      set(notificationsAtom, (state) => state.filter((n) => n.id !== id));
    }, notification.duration ?? 5000);
  },
);
const dismissNotificationAtom = atom(null, (_, set, id: string) =>
  set(notificationsAtom, (state) => state.filter((n) => n.id !== id)),
);

type NotificationsStore = {
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, "id">) => void;
  dismissNotification: (id: string) => void;
};

export const useNotificationStore = (): NotificationsStore => {
  const [notifications] = useAtom(notificationsAtom);
  const [, addNotification] = useAtom(addNotificationAtom);
  const [, dismissNotification] = useAtom(dismissNotificationAtom);

  return { notifications, addNotification, dismissNotification };
};
