import { Transition } from "@headlessui/react";
import { Fragment, type ReactElement } from "react";
import { HiX } from "react-icons/hi";
import {
  HiCheckCircle,
  HiExclamationCircle,
  HiInformationCircle,
  HiXCircle,
} from "react-icons/hi2";
import {
  type Notification as INotification,
  useNotificationStore,
} from "./store";

const icons: Record<INotification["type"], ReactElement> = {
  info: (
    <HiInformationCircle className="h-6 w-6 text-blue-500" aria-hidden="true" />
  ),
  success: (
    <HiCheckCircle className="h-6 w-6 text-green-500" aria-hidden="true" />
  ),
  warning: (
    <HiExclamationCircle
      className="h-6 w-6 text-yellow-500"
      aria-hidden="true"
    />
  ),
  error: <HiXCircle className="h-6 w-6 text-red-500" aria-hidden="true" />,
};

export type NotificationProps = {
  notification: INotification;
  onDismiss: (id: string) => void;
};

export const Notification = ({
  notification: { id, type, title, message },
  onDismiss,
}: NotificationProps) => {
  return (
    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
      <Transition
        show
        appear
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4" role="alert" aria-label={title}>
            <div className="flex items-center">
              <div className="flex-shrink-0">{icons[type]}</div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900">{title}</p>
                {message && (
                  <p className="mt-1 text-sm text-gray-500">{message}</p>
                )}
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  type="button"
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => onDismiss(id)}
                >
                  <span className="sr-only">Close</span>
                  <HiX className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export const Notifications = () => {
  const { notifications, dismissNotification } = useNotificationStore();

  return (
    <div
      aria-live="assertive"
      className="z-50 flex flex-col fixed inset-0 space-y-4 items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
          onDismiss={dismissNotification}
        />
      ))}
    </div>
  );
};
